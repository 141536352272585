<div
  *ngIf="vendorsState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Vendor</h4>
  </div>
  <mat-dialog-content *lhaLoading="vendorsState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveVendor()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Name</mat-label>
            <input matInput formControlName="vendorName" />
            <mat-error *ngIf="form.controls.vendorName.hasError('required')">
              Vendor name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="locations()"
            formControlName="locationId"
            bindValue="locationId"
            bindLabel="name"
            placeholder="Location"
          />
          <div
            *ngIf="
              form.controls.locationId.invalid &&
              form.controls.locationId.touched
            "
          >
            <mat-error *ngIf="form.controls.locationId.hasError('required')">
              Location is required.
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Short Name</mat-label>
            <input matInput formControlName="shortName" />
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Long Name</mat-label>
            <input matInput formControlName="longName" />
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Address</mat-label>
            <input matInput formControlName="address" />
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>PostCode</mat-label>
            <input matInput formControlName="postCode" />
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" />
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Country</mat-label>
            <input matInput formControlName="country" />
          </mat-form-field>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

import { PortalModule } from '@angular/cdk/portal';
import { Store } from '@ngrx/store';
import { AsyncPipe, NgClass, NgIf, NgFor, CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { PortalService } from '../../services/portal.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { ClientLocation } from '../../../../../services/src/lib/services/client-locations/interfaces/client-locations.interface';
import { UserActions } from '../../../../../services/src/lib/services/maintenance/store/actions/users.actions';
import { User } from 'libs/auth/src/lib/interfaces/user.interface';
import { Location } from 'libs/services/src/lib/services/maintenance/interfaces/location.interface';
import { Subject, takeUntil } from 'rxjs';
import { locationsFeature } from '../../../../../../libs/services/src/lib/services/maintenance/store/features';
import { RoleCheckService } from 'libs/services/src/lib/services/shared/role-check.service';
import { currentUserFeature } from 'libs/auth/src/lib/store/current-user/current-user.features';

@Component({
  selector: 'lha-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    MatToolbarModule,
    PortalModule,
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NgIf,
    NgClass,
    NgFor,
    MatTooltipModule,
    RouterLink,
    CommonModule,
  ],
})
export class HeaderComponent {
  store = inject(Store);

  @Input() locations: Location[] | null = null;
  @Input() clientLocations: ClientLocation[] | null = null;
  @Input() title: string | undefined;
  @Output() profileClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<void>();
  @ViewChild('appsMenu') menuTrigger!: MatMenuTrigger;
  unsubscribe: Subject<boolean> = new Subject();
  clientLocations$ = this.store.select(locationsFeature.selectLocations);
  latestLocations: Location[] | null = null;
  private readonly portalService = inject(PortalService);
  private readonly roleCheckService = inject(RoleCheckService);
  portal$ = this.portalService.getPortal('header');
  url = window.location.origin;
  parts = this.url.split('//')[1].split('.');
  tenantName = this.parts[0];
  tenant = this.tenantName.charAt(0).toUpperCase() + this.tenantName.slice(1);
  home = this.url;
  currentUserState = this.store.selectSignal(currentUserFeature.selectCurrentUserState);

  showProfile() {
    this.profileClicked.emit();
  }

  logout() {
    this.logoutClicked.emit();
  }

  updateUserActiveLocation(locationId: string) {
    if (this.currentUserState().user && this.currentUserState().user.locationId !== locationId) {
      const updatedUser = {
        ...this.currentUserState().user,
        locationId: locationId,
      } as User;
      this.store.dispatch(
        UserActions.edit_User_Location({
          user: updatedUser,
          userId: this.currentUserState().user.userId,
        })
      );
    }
  }

  grabLatestLocations() {
    this.clientLocations$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.latestLocations = res;
    });
    if (this.latestLocations) {
      return this.latestLocations?.filter((x) =>
        x.clientLocations.some(
          (x) => x.clientId === this.currentUserState().user?.clientId
        )
      );
    } else {
      return this.locations?.filter((x) =>
        x.clientLocations.some(
          (x) => x.clientId === this.currentUserState().user?.clientId
        )
      );
    }
  }

  get currentLocationName(): string {
    if (this.currentUserState().user && this.grabLatestLocations()) {
      const location = this.grabLatestLocations()?.find(
        (s) => s.locationId === this.currentUserState().user!.locationId
      );
      return location ? location.name : 'Unknown Location';
    }
    return 'Unknown Location';
  }
}

import { CargoesState } from '../../interfaces/cargoes-state.interface';
import { Cargo } from '../../interfaces/cargoes.interface';
import { maintenanceInitialState } from './maintenance.state';

export const cargoesInitialState: CargoesState = {
  cargoes: [],
  ...maintenanceInitialState,
  cargo: {
    cargoId: '',
    ccuId: '',
    familyId: '',
    typeId: '',
    sizeId: '',
    vendorId: '',
    description: '',
    length: 0,
    width: 0,
    height: undefined,
    tareMass: undefined,
    maxGrossWeight: undefined,
    category: 4,
    isDeckCargo: true,
    isPool: false,
  } as Cargo,
  locationCargoes: [],
  voyageId: '',
  cargoDescriptions: [],
  oneOffCargo: null,
};

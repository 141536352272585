import { NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import {
  FormControl,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormArray,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { LoadingDirective } from 'libs/components/src/lib/directives/loading.directive';
import { User } from 'libs/auth/src/lib/interfaces/user.interface';
import { UserActions } from 'libs/services/src/lib/services/maintenance/store/actions/users.actions';
import {
  locationsFeature,
  operatorsFeature,
  usersFeature,
} from 'libs/services/src/lib/services/maintenance/store/features';
import { InitialCapitalizeDirective } from 'libs/components/src/lib/directives/initial-capitalize.directive';
import { Constants } from '../../../../shared/application-config/email-pattern';
import { SingleSelectComponent } from 'libs/components/src/lib/components/single-select/single-select.component';
import { Location } from 'libs/services/src/lib/services/maintenance/interfaces/location.interface';
import { currentUserFeature } from 'libs/auth/src/lib/store/current-user/current-user.features';
import { ApplicationRole } from 'libs/auth/src/lib/interfaces/application-role.interface';

@Component({
  selector: 'lha-user-add-edit',
  standalone: true,
  imports: [
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    NgFor,
    ReactiveFormsModule,
    NgIf,
    MatSlideToggleModule,
    InitialCapitalizeDirective,
    SingleSelectComponent,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss'],
})
export class UserAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<UserAddEditComponent>);
  data: { user: User } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  constants = inject(Constants);

  usersState = this.store.selectSignal(usersFeature.selectUsersState);
  operators = this.store.selectSignal(operatorsFeature.selectOperators);
  applicationRoles = this.store.selectSignal(
    usersFeature.selectApplicationRoles
  );
  currentUser = this.store.selectSignal(currentUserFeature.selectUser);
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  filteredLocations = signal<Location[]>([]);
  form = new FormGroup({
    lastname: new FormControl<string>('', [Validators.required]),
    firstname: new FormControl<string>('', [Validators.required]),
    emailAddress: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.constants.emailPattern),
    ]),
    clientId: new FormControl<string>('', [Validators.required]),
    locationId: new FormControl<string>('', [Validators.required]),
    disabled: new FormControl<boolean>(false, [Validators.required]),
    roles: new FormArray([], [this.atLeastOneFieldHasValue('roles')]),
  });

  get rolesCtrl() {
    return this.form.get('roles') as FormArray;
  }

  ngOnInit(): void {
    this.disableAssetControl();
    this.subCloseDialog();
    this.pathForm(this.data.user ?? {});
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        ofType(UserActions.add_User_Success, UserActions.edit_User_Success)
      )
      .subscribe((action) => {
        this.dialogRef.close();
        const updatedUser = action.user;
        if (
          updatedUser.locationId !== this.form.get('locationId')?.value &&
          updatedUser.userId === this.currentUser()?.userId
        ) {
          window.location.reload();
        }
      });
  }

  disableAssetControl() {
    this.form.get('locationId')?.disable();
    this.form.get('clientId')?.valueChanges.subscribe((clientId) => {
      if (clientId) {
        this.form.get('locationId')?.enable();
        this.filteredLocations.set(
          this.locations().filter((x) =>
            x.clientLocations.some((y) => y.clientId === clientId)
          ) ?? []
        );
      } else {
        this.form.get('locationId')?.reset();
        this.form.get('locationId')?.disable();
      }
    });
  }

  private pathForm(user: User): void {
    this.form.patchValue({
      ...user,
      roles: [],
    });
    this.applicationRoles().forEach((item) => {
      this.rolesCtrl.push(this.addRole(item));
    });
    this.rolesCtrl.updateValueAndValidity();
  }

  addRole(item: ApplicationRole) {
    return new FormGroup({
      application: new FormControl(item.application.toLocaleLowerCase()),
      roles: this.addApplicationRoles(item.application),
    });
  }

  addApplicationRoles(application: string) {
    const index = this.data.user?.roles.findIndex(
      (item) =>
        item.application.toLocaleLowerCase() === application.toLocaleLowerCase()
    );
    return new FormControl(index > -1 ? this.data.user.roles[index].roles : []);
  }

  saveUser(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const user = this.form.value as unknown as User;
      user.roles = user.roles.filter((item) => item.roles.length);
      if (!this.data.user) {
        this.store.dispatch(UserActions.add_User({ user }));
      } else {
        this.store.dispatch(
          UserActions.edit_User({
            user,
            userId: this.data.user.userId,
          })
        );
      }
    }
  }

  atLeastOneFieldHasValue(fieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !(control instanceof FormArray) || !control.length) {
        return null;
      }  
      const hasValue = control.controls.some(group => {
        const field = group.get(fieldName);
        return field && field.value?.length;
      });
  
      return hasValue ? null : { atLeastOneFieldHasValue: true };
    };
  }
}

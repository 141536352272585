import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from 'libs/components/src/lib/functions/utility.functions';
import { AppSettings } from '../../interfaces/app-settings.interface';
import { Asset } from 'libs/services/src/lib/services/maintenance/interfaces/asset.interface';

export const SettingActions = createActionGroup({
  source: 'Setting',
  events: {
    initialise_Settings_Page: emptyProps(),
    load_Settings: emptyProps(),
    load_Settings_Success: props<{ settings: AppSettings }>(),
    load_Settings_Failure: errorProps(),
    add_Settings: props<{
      settings: AppSettings
    }>(),
    add_Settings_Success: props<{
      settings: AppSettings;
      successMessage: string;
    }>(),
    add_Settings_Failure: errorProps(),
    edit_Settings: props<{settings: AppSettings}>(),
    edit_Settings_Success: props<{
      settings: AppSettings;
      successMessage: string;
    }>(),
    edit_Settings_Failure: errorProps(),
    load_Ports: emptyProps(),
    load_Ports_Success: props<{ ports: Asset[] }>(),
    load_Ports_Failure: errorProps(),
  },
});

import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { usersInitialState } from './current-user.state';
import { CurrentUserActions } from './current-user.actions';

export const currentUserReducer = createReducer(
  usersInitialState,
  immerOn(CurrentUserActions.load_Current_User, (state) => {
    state.loading = true;
  }),
  immerOn(CurrentUserActions.load_Current_User_Success, (state, { user }) => {
    state.user = user;
    if (user.roles) {
      const flowRole = user.roles.find((role) => role.application === 'flow');
      state.hasFlowPermissions = flowRole ? flowRole.roles.length > 0 : false;

      const planRole = user.roles.find((role) => role.application === 'plan');
      state.hasPlanPermissions = planRole ? planRole.roles.length > 0 : false;

      const allocateRole = user.roles.find(
        (role) => role.application === 'allocate'
      );
      state.hasAllocatePermissions = allocateRole
        ? allocateRole.roles.length > 0
        : false;

      const containRole = user.roles.find(
        (role) => role.application === 'contain'
      );
      state.hasContainPermissions = containRole
        ? containRole.roles.length > 0
        : false;

      const requestRole = user.roles.find(
        (role) => role.application === 'request'
      );
      state.hasRequestPermissions = requestRole
        ? requestRole.roles.length > 0
        : false;
    }
    state.loading = false;
  }),
  immerOn(CurrentUserActions.load_Current_User_Failure, (state) => {
    state.loading = false;
  })
);

export const currentUserFeature = createFeature({
  name: 'currentUser',
  reducer: currentUserReducer,
  extraSelectors: ({ selectUser }) => ({
    selectCurrentUserExists: createSelector(
      selectUser,
      (selectUser) => !!selectUser.userId
    ),
  }),
});

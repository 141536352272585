import { HttpErrorResponse } from '@angular/common/http';
import { ActionCreatorProps, props } from '@ngrx/store';
import { DateFilterFn } from '@angular/material/datepicker';
import { inject } from '@angular/core';

export const makeDateRangeCheck = (
  startDate: string,
  endDate: string,
  skipTime = true,
  sameDate = true
) => {
  const startDateTime = skipTime
    ? new Date(new Date(startDate).toDateString()).getTime()
    : new Date(startDate).getTime();
  const endDateTime = skipTime
    ? new Date(new Date(endDate).toDateString()).getTime()
    : new Date(endDate).getTime();
  return (date: string): boolean => {
    const dateTime = skipTime
      ? new Date(new Date(date).toDateString()).getTime()
      : new Date(date).getTime();
    return sameDate
      ? (!startDate || startDateTime <= dateTime) &&
          (!endDate || (!!date && dateTime <= endDateTime))
      : (!startDate || startDateTime < dateTime) &&
          (!endDate || (!!date && dateTime < endDateTime));
  };
};

export const errorProps = (): ActionCreatorProps<{
  error: HttpErrorResponse;
  customMessage?: string;
}> => {
  return props<{ error: HttpErrorResponse; customMessage?: string }>();
};

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const dateAfterFilter = (
  date: Date | null
): DateFilterFn<Date | null> => {
  return (d: Date | null) => {
    const endDate = new Date(new Date(date ?? '').toDateString()).getTime();
    const calendarDate = new Date(new Date(d ?? '').toDateString()).getTime();
    return !endDate || endDate >= calendarDate;
  };
};

export const dateBeforeFilter = (
  date: Date | null
): DateFilterFn<Date | null> => {
  return (d: Date | null): boolean => {
    const startDate = new Date(new Date(date ?? '').toDateString()).getTime();
    const calendarDate = new Date(new Date(d ?? '').toDateString()).getTime();
    return !startDate || startDate <= calendarDate;
  };
};

export const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const timeAfter = (
  date: Date | null,
  time: { hrs?: number; min?: number; sec?: number }
): Date | null => {
  const dateTime = new Date(
    new Date(date ?? '').getTime() -
      toMilliseconds(time.hrs ?? 0, time.min ?? 0, time.sec ?? 0)
  );
  return !dateTime ? null : dateTime;
};

export const timeBefore = (
  date: Date | null,
  time: { hrs?: number; min?: number; sec?: number }
): Date | null => {
  const dateTime = new Date(
    new Date(date ?? '').getTime() +
      toMilliseconds(time.hrs ?? 0, time.min ?? 0, time.sec ?? 0)
  );
  return !dateTime ? null : dateTime;
};

export const getDefaultTime = (time: {
  hrs?: number;
  min?: number;
  sec?: number;
}): number[] => {
  return [
    time.hrs ?? new Date().getHours(),
    time.min ?? new Date().getMinutes(),
    time.sec ?? new Date().getSeconds(),
  ];
};

export const getTime = (
  date: string | Date,
  skipMilliseconds = true
): number => {
  return skipMilliseconds
    ? new Date(new Date(date).toJSON().replace(/\.\d+Z/, '') + 'Z').getTime()
    : new Date(date).getTime();
};

export function getOneMonthAgo(): Date {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date;
}

export function getOneMonthFromNow(): Date {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return date;
}

export function objectToFormData<T extends Record<string, any>>(
  obj: T,
  form?: FormData,
  namespace?: string
): FormData {
  const formData = form || new FormData();

  for (const key of Object.keys(obj)) {
    const value = obj[key];
    const formKey = namespace ? `${namespace}[${key}]` : key;

    if (value instanceof File || value instanceof Blob) {
      formData.append(formKey, value);
    } else if (value instanceof Date) {
      formData.append(formKey, value.toISOString()); // Convert Date to ISO string
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        objectToFormData({ [index]: item }, formData, formKey);
      });
    } else if (value !== null && typeof value === 'object') {
      objectToFormData(value, formData, formKey);
    } else if (value !== undefined && value !== null) {
      formData.append(formKey, value.toString());
    }
  }

  return formData;
}

export function removeDuplicates<T, K extends keyof T>(
  array: T[],
  key: K
): T[] {
  const unique = new Map<string | number, T>();

  array.forEach(item => {
    const value = item[key] as unknown as string | number;
    if (!unique.has(value)) {
      unique.set(value, item);
    }
  });
  
  return Array.from(unique.values());
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Operator } from '../../interfaces/operator.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const OperatorActions = createActionGroup({
  source: 'Operator',
  events: {
    update_Operator_Queries: props<{ query: SearchQuery }>(),
    load_Operators_Page: emptyProps(),
    load_Operators: emptyProps(),
    load_Operators_Success: props<{ operators: Operator[] }>(),
    load_Operators_Failure: errorProps(),
    remove_Operator: props<{ id: string }>(),
    remove_Operator_Success: props<{
      operator: Operator;
      successMessage: string;
    }>(),
    remove_Operator_Failure: errorProps(),
    open_Operator_Dialog: props<{ operator: Operator | null }>(),
    close_Edit_Dialog: props<{ close: boolean }>(),
    add_Operator: props<{ operator: FormData }>(),
    add_Operator_Success: props<{
      operator: Operator;
      successMessage: string;
    }>(),
    add_Operator_Failure: errorProps(),
    edit_Operator: props<{ operatorId: string; operator: FormData }>(),
    edit_Operator_Success: props<{
      operator: Operator;
      successMessage: string;
    }>(),
    edit_Operator_Failure: errorProps(),
    load_Client_Logo: props<{ clientLogoId: string }>(),
    load_Client_Logo_Success: props<{
      clientLogo: Blob;
    }>(),
    load_Client_Logo_Failure: errorProps(),
  },
});

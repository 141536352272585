<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
/>

<mat-toolbar>
  <div class="title-container">
    <a class="title" href="{{ home }}"
      >Lighthouse
      <a *ngIf="title">
        <mat-icon class="chevron">chevron_right</mat-icon> {{ title }}</a
      ></a
    >
  </div>
  <ng-template
    class="header-info"
    [cdkPortalOutlet]="portal$ | async"
  ></ng-template>
  <div class="profile">
    <div class="tenant-name">
      <p>{{ tenant }}</p>
    </div>
    <div class="location">
      <button
        mat-icon-button
        [matTooltip]="'Locations'"
        class="location-button"
        [matMenuTriggerFor]="locationMenu"
      >
        <span class="location-button-text">{{ currentLocationName }}</span>
        <span class="material-symbols-outlined" style="color: #999999">
          arrow_drop_down
        </span>
      </button>
      <mat-menu #locationMenu="matMenu">
        <ng-container *ngFor="let location of this.grabLatestLocations()">
          <button
            (click)="updateUserActiveLocation(location.locationId)"
            class="location-menu"
            mat-menu-item
          >
            {{ location.name }}
          </button>
        </ng-container>
      </mat-menu>
    </div>

    <div class="divider">|</div>

    <div>
      <button mat-icon-button [matTooltip]="'Notifications'">
        <mat-icon class="material-icons-outlined">notifications_none</mat-icon>
        <div class="custom-tooltip custom-tooltip--right">
          <div class="custom-tooltip__text">Notifications</div>
        </div>
      </button>
    </div>

    <button mat-icon-button [matTooltip]="'Help'">
      <mat-icon class="material-icons-outlined">help_outline</mat-icon>
    </button>

    <button
      [matTooltip]="'Profile'"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="profile-button"
      color="primary"
    >
      <mat-icon class="material-icons-outlined">perm_identity</mat-icon>
    </button>

    <button
      class="app-icon-button"
      mat-icon-button
      [matMenuTriggerFor]="appsMenu"
    >
      <mat-icon>apps </mat-icon>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="showProfile()">Profile</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>

  <mat-menu style="width:323px" #appsMenu="matMenu">
    <span class="switch pb-16">Switch to...</span>

    <a
      *ngIf="currentUserState().hasAllocatePermissions"
      class="app-menu"
      href="{{ url }}/allocate/"
    >
      <img src="assets/allocate.svg" />
      <div class="app-menu-content">
        <span class="app-menu-title">Allocate</span>
        <span class="app-menu-subtitle">Allocate</span>
      </div>
    </a>
    <a
      *ngIf="currentUserState().hasPlanPermissions"
      class="app-menu"
      href="{{ url }}/plan/"
    >
      <img src="assets/plan.svg" />
      <div class="app-menu-content">
        <span class="app-menu-title">Plan</span>
        <span class="app-menu-subtitle">Port Activity Planning</span>
      </div>
    </a>
    <a
      *ngIf="currentUserState().hasFlowPermissions"
      class="app-menu"
      href="{{ url }}/flow/"
    >
      <img src="assets/flow.svg" />
      <div class="app-menu-content">
        <span class="app-menu-title">Flow</span>
        <span class="app-menu-subtitle">Quayside Management</span>
      </div>
    </a>
    <a
      *ngIf="currentUserState().hasContainPermissions"
      class="app-menu"
      href="{{ url }}/contain/"
    >
      <img src="assets/contain.svg" />
      <div class="app-menu-content">
        <span class="app-menu-title">Contain</span>
        <span class="app-menu-subtitle">CCU Fleet Management</span>
      </div>
    </a>
    <a
      *ngIf="currentUserState().hasRequestPermissions"
      class="app-menu"
      href="{{ url }}/request/"
    >
      <img src="assets/request.svg" />
      <div class="app-menu-content">
        <span class="app-menu-title">Request</span>
        <span class="app-menu-subtitle">Offshore Transport Request</span>
      </div>
    </a>
    <!-- TODO: Re-enable when admin is available -->
    <!-- <a *ngIf="doesUserHaveAdminPermissions()" class="app-menu">
      <img src="assets/allocate.svg">
      <div class="app-menu-content">
        <span class="app-menu-title">Lighthouse Admin</span>
        <span class="app-menu-subtitle">Management</span>
      </div>
    </a> -->
  </mat-menu>
</mat-toolbar>

import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { LoadingDirective } from 'libs/components/src/lib/directives/loading.directive';
import { CroppingImageDialogComponent } from 'libs/components/src/lib/components/cropping-image-dialog/cropping-image-dialog.component';

import { Operator } from 'libs/services/src/lib/services/maintenance/interfaces/operator.interface';
import {
  operatorsFeature,
  locationsFeature,
  reportTypesFeature,
} from 'libs/services/src/lib/services/maintenance/store/features';
import { OperatorActions } from 'libs/services/src/lib/services/maintenance/store/actions/operators.actions';
import { MultiselectComponent } from 'libs/components/src/lib/components/multiselect/multiselect.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  filePatternValidator,
  openFileSelector,
} from 'libs/components/src/lib/functions/file-helpers';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { objectToFormData } from 'libs/components/src/lib/functions/utility.functions';

@Component({
  selector: 'lha-operator-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgIf,
    MatSlideToggleModule,
    MultiselectComponent,
    CdkDrag,
    CdkDragHandle,
    CroppingImageDialogComponent,
    ConfirmDialogModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './operator-add-edit.component.html',
  styleUrls: ['./operator-add-edit.component.scss'],
})
export class OperatorAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  dialogRef = inject(MatDialogRef<OperatorAddEditComponent>);
  private readonly confirmationService = inject(ConfirmationService);
  private sanitizer = inject(DomSanitizer);

  data: { operator: Operator } = inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  snackBar = inject(MatSnackBar);

  operatorState = this.store.selectSignal(
    operatorsFeature.selectOperatorsState
  );
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  reportTypes = this.store.selectSignal(reportTypesFeature.selectReportTypes);
  isAdd = true;
  unsubscribe: Subject<boolean> = new Subject();

  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    isActive: new FormControl<boolean>(false),
    reportTypeIds: new FormControl<string[]>([]),
    locationIds: new FormControl<string[]>([]),
    customsCompliant: new FormControl<boolean>(false),
    vatNumber: new FormControl<string>(''),
    euNumber: new FormControl<string>(''),
    picture: new FormControl<SafeUrl>(''),
    hasImage: new FormControl<boolean>(false),
  });

  logCtrl = this.form.controls.picture;
  visibleCropDialog = false;
  imageChangedEvent: Event | null = null;
  accept = '.jpeg, .jpg, .png';
  photoUrl: SafeUrl = '';

  ngOnInit() {
    this.subCloseDialog();
    this.initAddEdit();

    this.action
      .pipe(ofType(OperatorActions.load_Client_Logo_Success))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ clientLogo }) => {
        this.photoUrl = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(clientLogo)
        );
      });
  }

  private initAddEdit(): void {
    this.isAdd = !this.data.operator;
    if (!this.isAdd) {
      this.pathForm(this.data.operator);
    }
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          OperatorActions.add_Operator_Success,
          OperatorActions.edit_Operator_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private pathForm(operator: Operator): void {
    this.form.patchValue({
      ...operator,
      hasImage: !!operator.clientLogoId,
    });
    if (operator.clientLogoId) {
      this.store.dispatch(
        OperatorActions.load_Client_Logo({
          clientLogoId: operator.clientLogoId!,
        })
      );
    }
  }

  saveOperator(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = objectToFormData(this.form.value);

    if (this.isAdd) {
      this.store.dispatch(OperatorActions.add_Operator({ operator: model }));
    } else {
      this.store.dispatch(
        OperatorActions.edit_Operator({
          operator: model,
          operatorId: this.data.operator.clientId,
        })
      );
    }
  }

  async selectFile() {
    this.imageChangedEvent = (await openFileSelector(this.accept)) as Event;
    const file = (this.imageChangedEvent as any).target.files[0];
    const pattern = '(.*?).(jpg|jpeg|png)$';
    if (filePatternValidator(file, pattern) && file.size <= 5 * 1024 * 1024) {
      this.visibleCropDialog = true;
    } else {
      if (!filePatternValidator(file, pattern)) {
        this.snackBar.open(
          'Unsupported file format. Please upload a .png, .jpg or .jpeg file.',
          'Close',
          {
            duration: 3000,
            panelClass: 'snackbar--error',
          }
        );
      } else {
        this.snackBar.open(
          'File size exceeds the 5 MB limit. Please upload a smaller file.',
          'Close',
          {
            duration: 3000,
            panelClass: 'snackbar--error',
          }
        );
      }
    }
  }

  removePhoto() {
    this.confirmationService.confirm({
      header: 'Confirm Completion',
      message:
        'Are you sure you want to remove your photo? We will replace it with default avatar.',
      acceptLabel: 'Remove',
      rejectLabel: 'Cancel',
      acceptButtonStyleClass: 'button-primary',
      rejectButtonStyleClass: 'btn-secondary',
      accept: () => {
        this.logCtrl.setValue(null);
        this.form.get('hasImage')?.setValue(false);
        this.photoUrl = '';
      },
    });
  }

  saveImage(event: { blob: Blob; safeUrl: SafeUrl }) {
    this.snackBar.open('Logo updated successfully', 'Close', {
      duration: 3000,
      panelClass: 'snackbar--success',
    });
    this.logCtrl.setValue(event.blob);
    this.form.get('hasImage')?.setValue(true);
    this.photoUrl = event.safeUrl as string;
    this.visibleCropDialog = false;
  }
}

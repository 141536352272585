<div
  *ngIf="operatorState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ isAdd ? 'Add ' : 'Edit ' }} Operator</h4>
  </div>
  <mat-dialog-content *lhaLoading="operatorState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveOperator()">
      <div class="form__block">
        <div class="form__box">
          <div class="d-flex gap-8">
            <img
              *ngIf="photoUrl; else emptyImage"
              [src]="photoUrl"
              width="80"
              height="80"
            />
            <ng-template #emptyImage>
              <img src="assets/no-profile-picture.svg" width="80" height="80" />
            </ng-template>

            <div
              class="d-flex flex-direction-column gap-8 justify-content-center"
            >
              <button (click)="selectFile()" type="button" class="btn-text">
                Upload Photo
              </button>
              <button
                *ngIf="logCtrl.value || photoUrl"
                (click)="removePhoto()"
                type="button"
                class="btn-text"
              >
                Remove Photo
              </button>
            </div>
          </div>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Operator Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              Operator name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="locationIds"
            bindValue="locationId"
            bindLabel="name"
            [items]="locations()"
            placeholder="Locations"
          >
          </lha-multiselect>
        </div>
        <div class="form__box">
          <lha-multiselect
            formControlName="reportTypeIds"
            bindValue="reportTypeId"
            bindLabel="name"
            [items]="reportTypes()"
            placeholder="Report Types"
          >
          </lha-multiselect>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="false">
            <mat-label>VAT Number</mat-label>
            <input matInput formControlName="vatNumber" />
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="false">
            <mat-label>EU Number</mat-label>
            <input matInput formControlName="euNumber" />
          </mat-form-field>
        </div>
        <div class="form__box form__box--mb20">
          <mat-slide-toggle formControlName="customsCompliant">
            Custom Compliant
          </mat-slide-toggle>
        </div>
        <div class="form__box form__box--mb20">
          <mat-slide-toggle formControlName="isActive">
            Is Active
          </mat-slide-toggle>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit">
          {{ isAdd ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>

<lib-cropping-image-dialog
  [visible]="visibleCropDialog"
  [imageChangedEvent]="imageChangedEvent"
  (hideDialog)="visibleCropDialog = false"
  (saveImage)="saveImage($event)"
/>

<p-confirmDialog class="confirm-dialog"></p-confirmDialog>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  downloadFile(data: ArrayBuffer, fileName: string): void {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(
      new Blob([data], { type: 'application/octet-binary;charset=utf-8' })
    );
    a.download = `${fileName}.xlsx`;
    a.click();
  }

  importTemplate(event: Event): Observable<any> {
    const file = (event.target as HTMLInputElement).files?.item(0);
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    return of(formData);
  }

  createFormData(
    data: Record<string, any>,
    event: Event | string | null,
    dateFields: string[] = []
  ): FormData {
    const file =
      typeof event !== 'string' && event
        ? (event.target as HTMLInputElement)?.files?.item(0)
        : null;
    const formData = new FormData();

    Object.entries(data).forEach((item) => {
      dateFields.includes(item[0])
        ? formData.append(item[0], new Date(item[1]).toJSON())
        : formData.append(item[0], item[1]);
    });

    if (file) {
      formData.set('picture', file);
    }

    return formData;
  }
}

<p-dialog
  [draggable]="false"
  [closable]="false"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '1040px' }"
>
  <ng-template pTemplate="header">
    <div class="header">Crop Logo</div>
  </ng-template>
  <ng-template pTemplate="content">
    <p-divider class="mb-12" />
    <div class="mt-20">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="button-primary mr-8"
      type="button"
      (click)="hideDialog.emit(false)"
    >
      Close
    </button>
    <button class="btn-secondary" type="button" (click)="save()">Save</button>
  </ng-template>
</p-dialog>

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingActions } from '../actions/setting.actions';
import { AppSettings } from '../../interfaces/app-settings.interface';
import { Asset } from 'libs/services/src/lib/services/maintenance/interfaces/asset.interface';
import { AssetService } from 'libs/services/src/lib/services/maintenance/asset.service';

export const initialise_Settings_Page = createEffect(
  (actions = inject(Actions)) => {
    return actions.pipe(
      ofType(SettingActions.initialise_Settings_Page),
      switchMap(() => [
        SettingActions.load_Settings(),
        SettingActions.load_Ports(),
      ])
    );
  },
  {
    functional: true,
  }
);

export const loadSettings = createEffect(
  (actions = inject(Actions), settingsService = inject(SettingsService)) => {
    return actions.pipe(
      ofType(SettingActions.load_Settings),
      mergeMap(() =>
        settingsService.getSettings().pipe(
          map((res: AppSettings) =>
            SettingActions.load_Settings_Success({ settings: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SettingActions.load_Settings_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadPorts = createEffect(
  (actions = inject(Actions), assetService = inject(AssetService)) => {
    return actions.pipe(
      ofType(SettingActions.load_Ports),
      mergeMap(() =>
        assetService.loadPorts().pipe(
          map((res: Asset[]) =>
            SettingActions.load_Ports_Success({ ports: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SettingActions.load_Ports_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addSetting = createEffect(
  (actions = inject(Actions), settingsService = inject(SettingsService)) => {
    return actions.pipe(
      ofType(SettingActions.add_Settings),
      mergeMap((action) =>
        settingsService
          .addSettings(action.settings)
          .pipe(
            map((res: AppSettings) =>
              SettingActions.add_Settings_Success({
                settings: res,
                successMessage: 'Setting added successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(SettingActions.add_Settings_Failure({ error: error }))
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const editSetting = createEffect(
  (actions = inject(Actions), settingsService = inject(SettingsService)) => {
    return actions.pipe(
      ofType(SettingActions.edit_Settings),
      mergeMap((action) =>
        settingsService
          .editSettings(
            action.settings,
            action.settings.settingId
          )
          .pipe(
            map((res: AppSettings) =>
              SettingActions.edit_Settings_Success({
                settings: res,
                successMessage: 'Setting edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(SettingActions.edit_Settings_Failure({ error: error }))
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';

import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';

import {
  ImageCroppedEvent,
  ImageCropperComponent,
  LoadedImage,
} from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'lib-cropping-image-dialog',
  templateUrl: './cropping-image-dialog.component.html',
  styleUrls: ['./cropping-image-dialog.component.css'],
  imports: [DialogModule, DividerModule, ImageCropperComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CroppingImageDialogComponent {
  @Input() visible = false;
  @Input() imageChangedEvent: Event | null = null;
  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() saveImage = new EventEmitter<{
    blob: Blob;
    safeUrl: SafeUrl;
  }>();
  private readonly sanitizer = inject(DomSanitizer);
  croppedImageBlob: Blob | null = null;
  croppedImageSafeUrl: SafeUrl = '';

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBlob = event.blob!;
    this.croppedImageSafeUrl = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl!
    );
    // event.blob can be used to upload the cropped image
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  save() {
    this.saveImage.emit({
      blob: this.croppedImageBlob!,
      safeUrl: this.croppedImageSafeUrl,
    });
  }
}

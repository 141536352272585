import { UserState } from '../../interfaces/current-user-state.interface';

export const usersInitialState: UserState = {
  user: {
    userId: '',
    lastname: '',
    firstname: '',
    fullName: '',
    passwordReset: '',
    emailAddress: '',
    disabled: false,
    clientId: '',
    locationId: '',
    locationPlanMode: '',
    roles: [],
    roleDescription: '',
  },
  hasFlowPermissions: false,
  hasRequestPermissions: false,
  hasPlanPermissions: false,
  hasAllocatePermissions: false,
  hasContainPermissions: false,
  loading: false,
  resetPasswordLoading: false,
};

<div
  *ngIf="usersState()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary=".cdk-overlay-container"
>
  <div mat-dialog-title>
    <h4>{{ !data.user ? 'Add ' : 'Edit ' }} User</h4>
  </div>
  <mat-dialog-content *lhaLoading="usersState().loading.createEdit">
    <form [formGroup]="form" (ngSubmit)="saveUser()">
      <div class="form__block">
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstname" />
            <mat-error *ngIf="form.controls.firstname.hasError('required')">
              First name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastname" />
            <mat-error *ngIf="form.controls.lastname.hasError('required')">
              Last name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Email Address</mat-label>
            <input matInput name="email" formControlName="emailAddress" />
            <mat-error *ngIf="form.controls.emailAddress.hasError('required')">
              Email address is required.
            </mat-error>
            <mat-error *ngIf="form.controls.emailAddress.hasError('pattern')">
              Enter a valid email address
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="operators()"
            formControlName="clientId"
            bindValue="clientId"
            placeholder="Operator"
          />
          <div
            *ngIf="
              form.controls.clientId.invalid && form.controls.clientId.touched
            "
          >
            <mat-error *ngIf="form.controls.clientId.hasError('required')">
              Operator is required
            </mat-error>
          </div>
        </div>
        <div class="form__box">
          <lha-single-select
            [options]="filteredLocations()"
            formControlName="locationId"
            bindValue="locationId"
            placeholder="locations"
          />
          <div
            *ngIf="
              form.controls.locationId.invalid &&
              form.controls.locationId.touched
            "
          >
            <mat-error *ngIf="form.controls.locationId.hasError('required')">
              A Location is required
            </mat-error>
          </div>
        </div>
        <div formArrayName="roles" *ngIf="rolesCtrl.controls.length">
          <p>Assign User Roles</p>
          <hr />
          <div
            class="form__box"
            *ngFor="let applicationRole of applicationRoles(); let i=index"
          >
            <div class="dropdown" [formGroupName]="i">
              <mat-form-field appearance="outline" hideRequiredMarker="true">
                <mat-label style="color: red" *ngIf="rolesCtrl.invalid">
                  Please select a role for: {{ applicationRole.application }}
                </mat-label>
                <mat-label *ngIf="rolesCtrl.valid">
                  Select Roles for: {{ applicationRole.application }}
                </mat-label>
                <mat-select
                  formControlName="roles"
                  multiple
                >
                  <mat-option
                    *ngFor="let role of applicationRole.roles"
                    [value]="role.value"
                  >
                    <ng-container>
                      {{role.title }}
                    </ng-container>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <mat-dialog-actions align="end">
        <div *ngIf="data.user">
          <mat-slide-toggle
            style="padding-right: 20px"
            formControlName="disabled"
            >Disabled</mat-slide-toggle
          >
        </div>
        <button mat-raised-button color="primary" type="submit">
          {{ !data.user ? 'Add ' : 'Save ' }}
        </button>
        <button
          mat-raised-button
          color="warn"
          type="button"
          mat-dialog-close=""
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>
